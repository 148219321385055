import React from "react";
import "./footer.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import whatsappLogo from "../assets/whatsapp-logo.png";

const Footer = () => {
  const [minutes, setMinutes] = useState(15);
  const [seconds, setSeconds] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = () => {
    setIsLoading(true);
    setTimeout(() => {
      navigate("/topics");
      window.scrollTo(0, 0);
    }, 800);
  };

  useEffect(() => {
    let timer;

    if (minutes === 0 && seconds === 0) {
      clearInterval(timer);
    } else {
      timer = setInterval(() => {
        if (seconds === 0) {
          setMinutes((prevMinutes) => prevMinutes - 1);
          setSeconds(59);
        } else {
          setSeconds((prevSeconds) => prevSeconds - 1);
        }
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [minutes, seconds]);

  useEffect(() => {
    localStorage.setItem("timer", JSON.stringify({ minutes, seconds }));
  }, [minutes, seconds]);

  useEffect(() => {
    const savedTimer = JSON.parse(localStorage.getItem("timer"));
    if (savedTimer) {
      setMinutes(savedTimer.minutes);
      setSeconds(savedTimer.seconds);
    }
  }, []);

  const [isHomePage, setIsHomePage] = useState(true);

  useEffect(() => {
    const checkHomePage = () => {
      setIsHomePage(window.scrollY <= 200);
    };

    const toggleFooter = () => {
      const footer = document.getElementById("footer-1");
      if (footer) {
        footer.style.display = isHomePage ? "none" : "flex";
      }
    };

    window.addEventListener("scroll", () => {
      checkHomePage();
      toggleFooter();
    });

    checkHomePage();
    toggleFooter();

    return () => {
      window.removeEventListener("scroll", toggleFooter);
    };
  }, [isHomePage]);

  return (
    <footer id="footer-1">
      <div className="price">
        <h2>Register Now Only at</h2>
        <div className="price-quote">
          <b>₹ 499</b>
          <p>₹ 4,999</p>
        </div>
      </div>
      <div className="price-3">
        <a href="https://rzp.io/l/OXaOV8q" target="_blank" rel="noreferrer">
          <button className="footer-btn">Book Your Spot Now</button>
        </a>

        <button
          className="footer-btn2"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "DETAILED SYLLABUS"}
        </button>

        <h3>
          Offer Ends in{" "}
          <span class="minutes">{String(minutes).padStart(2, "0")}</span>
          <span>:</span>
          <span class="seconds">{String(seconds).padStart(2, "0")}</span> Mins
        </h3>
      </div>
      <a
        href="https://wa.me/918667049308"
        className="whatsapp-link"
        title="WhatsApp Us"
        target="_blank"
        rel="noreferrer"
      >
        <img src={whatsappLogo} alt="whatsapp-link" />
      </a>
    </footer>
  );
};

export default Footer;
