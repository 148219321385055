import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-scroll";
import "./section_1.css";
import { useSpring, animated } from "react-spring";
import ScrollTrigger from "react-scroll-trigger";

// import { motion } from "framer-motion";
// import { TypeAnimation } from "react-type-animation";
import companyLogo from "../assets/sg-profile-logo.jpg";

function Number({ n, onAnimationComplete }) {
  const [animatedOnce, setAnimatedOnce] = useState(false);

  const { number } = useSpring({
    from: { number: 0 },
    number: n,
    delay: 200,
    config: { mass: 1, tension: 20, friction: 10 },
    onRest: () => {
      if (!animatedOnce) {
        onAnimationComplete();
        setAnimatedOnce(true);
      }
    },
  });

  return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>;
}

const Section1 = () => {
  let sections = document.querySelectorAll("section");

  window.onscroll = () => {
    sections.forEach((sec) => {
      let top = window.scrollY;
      let offset = sec.offsetTop - 150;
      let height = sec.offsetHeight;

      if (top >= offset - height / 2 && top < offset + height) {
        sec.classList.add("show-animate");
      } else {
        sec.classList.remove("show-animate");
      }
    });
  };

  const [minutes, setMinutes] = useState(15);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let timer;

    if (minutes === 0 && seconds === 0) {
      clearInterval(timer);
    } else {
      timer = setInterval(() => {
        if (seconds === 0) {
          setMinutes((prevMinutes) => prevMinutes - 1);
          setSeconds(59);
        } else {
          setSeconds((prevSeconds) => prevSeconds - 1);
        }
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [minutes, seconds]);

  useEffect(() => {
    localStorage.setItem("timer", JSON.stringify({ minutes, seconds }));
  }, [minutes, seconds]);

  useEffect(() => {
    const savedTimer = JSON.parse(localStorage.getItem("timer"));
    if (savedTimer) {
      setMinutes(savedTimer.minutes);
      setSeconds(savedTimer.seconds);
    }
  }, []);

  //counterAnimationn

  const [counterOn, setCounterOn] = useState(false);

  const handleScrollEnter = () => {
    setCounterOn(true);
  };

  const handleScrollExit = () => {
    setCounterOn(false);
  };

  const handleAnimationComplete = () => {
    // Animation completed
  };

  //Mousefollowereffect
  // let mousefollower = document.querySelectorAll(".quotes-2");
  // mousefollower.forEach((card) => {
  //   card.onmousemove = function (e) {
  //     let x = e.pageX - card.offsetLeft;
  //     let y = e.pageY - card.offsetTop;

  //     card.style.setProperty("--x", x + "px");
  //     card.style.setProperty("--y", y + "px");
  //   };
  // });

  return (
    <section id="section1" className="sec-1 show-animate">
      <div className="sec-1-div ">
        <div className="logo-img">
          <img className="animate" src={companyLogo} alt="company-logo" />
          <div className="title-div">
            <h1 className="company-name">StructuralGuru </h1>
            <p className="company-quote">(We Create Consultants!)</p>
          </div>
        </div>

        <div className="quotes">
          <p className="quotes-2">
            WELCOME TO MASTERY COURSE ON STRUCTURAL DESIGN AND DETAILING IN
            5-SOFTWARE'S !
          </p>

          <div className="softwares-name">
            <p>STAAD PRO, ETABS, RCDC, IDEA STATICA, SAFE</p>
          </div>

          {/* <TypeAnimation
            sequence={[
              "STAAD PRO",
              1500,
              "Etabs",
              1500,
              "RCDC",
              1500,
              "IDEA Statica",
              1500,
              "SAFE",
              1500,
            ]}
            wrapper="span"
            speed={180}
            style={{
              fontSize: "2em",
              fontWeight: "700",
              display: "inline-block",
              color: "rgb(0, 255, 247)",
              zIndex: "100",
            }}
            repeat={Infinity}
            className="typeanimation"
          /> */}

          <p className="motto-quote">
            Help Each Other, Grow Together !
            {/* <span className="showRigth-animate"></span> */}
          </p>
        </div>

        <div className="counter-timer">
          <ScrollTrigger onEnter={handleScrollEnter} onExit={handleScrollExit}>
            <div className="counter-div">
              <div className="numberCardview">
                <h1>
                  {counterOn && (
                    <Number
                      n={1214}
                      onAnimationComplete={handleAnimationComplete}
                    />
                  )}
                  <span>+</span>
                </h1>
                <p>
                  Number of students <span style={{ color: "white" }}></span>
                </p>
              </div>

              <div className="numberCardview">
                <h1>
                  {counterOn && (
                    <Number
                      n={3847}
                      onAnimationComplete={handleAnimationComplete}
                    />
                  )}
                  <span>+</span>
                </h1>
                <p>Total course purchased</p>
              </div>
            </div>
          </ScrollTrigger>

          <div className="offer-timer-div">
            <div className="offer-end-main">
              <h3>Register in next</h3>

              <div className="offer-end-div">
                <div className="time-box">
                  <span className="minutes">
                    {String(minutes).padStart(2, "0")}
                  </span>
                  <span className="min">Minutes</span>
                </div>

                <div className="time-box">
                  <span className="seconds">
                    {String(seconds).padStart(2, "0")}
                  </span>
                  <span className="sec">Seconds</span>
                </div>
              </div>
            </div>

            <Link
              activeClass="active"
              to="paymentId"
              spy={true}
              smooth={true}
              offset={50}
              duration={1000}
              className="offer-button"
            >
              <button>Register Now Only at Rs.499/-</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section1;
