import React, { useState } from "react";
import "./tabsAnimation.css"; // assuming you have some CSS for styling
import { useNavigate } from "react-router-dom";

const Tabs = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    setTimeout(() => {
      navigate("/topics");
      window.scrollTo(0, 0);
    }, 800);
  };

  return (
    <section className="tab-section">
      <div className="tabs-container">
        <div className="tabs">
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`tab ${activeTab === index ? "active" : ""}`}
              onClick={() => handleTabClick(index)}
            >
              {tab.title}
            </div>
          ))}
        </div>
        <div className="content">{tabs[activeTab].content}</div>
        {/* Call handleSubmit when the button is clicked */}
        <button className="nav-btn" onClick={handleSubmit} disabled={isLoading}>
          {isLoading ? "Loading..." : "DETAILED SYLLABUS"}
        </button>
      </div>
    </section>
  );
};

const tabs = [
  {
    title: "STAAD Pro",
    content: (
      <div className="pro-plan-column-2">
        <div className="pro-plan-mode-2">
          <h1 className="plans-h1-2 basic-h1-2"> STAAD PRO </h1>
        </div>

        <p className="plans-p3-2">
          for 1-month access to videos and lifetime access to files.
        </p>
        <p className="plans-p2-2">
          Get started with our Basic plan and kickstart your learning journey
        </p>

        <div className="payment-center-line-2"></div>

        <ul className="basic-plan-features-2">
          <li>Duration - 1 month.</li>

          <li>20 hrs pre-recorded videos.</li>
          <li>Doubts & clarification in online/phone.</li>
          <li>Pdf Code books, Notes and xl sheets supply.</li>

          <li>Certification.</li>
        </ul>
      </div>
    ),
  },
  {
    title: "Etabs",
    content: (
      <div className="pro-plan-column-2">
        <div className="pro-plan-mode-2">
          <h1 className="plans-h1-2 basic-h1-2"> ETABS </h1>
        </div>

        <p className="plans-p3-2">
          for 1-month access to videos and lifetime access to files.
        </p>
        <p className="plans-p2-2">
          Get started with our Basic plan and kickstart your learning journey
        </p>

        <div className="payment-center-line-2"></div>

        <ul className="basic-plan-features-2">
          <li>Duration - 1 month.</li>

          <li>25 hrs pre-recorded videos.</li>
          <li>Doubts & clarification in online/phone.</li>
          <li>Pdf Code books, Notes and xl sheets supply.</li>

          <li>Certification.</li>
        </ul>
      </div>
    ),
  },
  {
    title: "RCDC",
    content: (
      <div className="pro-plan-column-2">
        <div className="pro-plan-mode-2">
          <h1 className="plans-h1-2 basic-h1-2"> RCDC </h1>
        </div>

        <p className="plans-p3-2">
          for 1-month access to videos and lifetime access to files.
        </p>
        <p className="plans-p2-2">
          Get started with our Basic plan and kickstart your learning journey
        </p>

        <div className="payment-center-line-2"></div>

        <ul className="basic-plan-features-2">
          <li>Duration - 1 month.</li>

          <li>15 hrs pre-recorded videos.</li>
          <li>Doubts & clarification in online/phone.</li>
          <li>Pdf Code books, Notes and xl sheets supply.</li>

          <li>Certification.</li>
        </ul>
      </div>
    ),
  },
  {
    title: "IDEA Statica",
    content: (
      <div className="pro-plan-column-2">
        <div className="pro-plan-mode-2">
          <h1 className="plans-h1-2 basic-h1-2"> IDEA STATICA </h1>
        </div>

        <p className="plans-p3-2">
          for 1-month access to videos and lifetime access to files.
        </p>
        <p className="plans-p2-2">
          Get started with our Basic plan and kickstart your learning journey
        </p>

        <div className="payment-center-line-2"></div>

        <ul className="basic-plan-features-2">
          <li>Duration - 1 month.</li>

          <li>10 hrs pre-recorded videos.</li>
          <li>Doubts & clarification in online/phone.</li>
          <li>Pdf Code books, Notes and xl sheets supply.</li>

          <li>Certification.</li>
        </ul>
      </div>
    ),
  },
  {
    title: "SAFE",
    content: (
      <div className="pro-plan-column-2">
        <div className="pro-plan-mode-2">
          <h1 className="plans-h1-2 basic-h1-2"> SAFE </h1>
        </div>

        <p className="plans-p3-2">
          for 1-month access to videos and lifetime access to files.
        </p>
        <p className="plans-p2-2">
          Get started with our Basic plan and kickstart your learning journey
        </p>

        <div className="payment-center-line-2"></div>

        <ul className="basic-plan-features-2">
          <li>Duration - 1 month.</li>

          <li>5 hrs pre-recorded videos.</li>
          <li>Doubts & clarification in online/phone.</li>
          <li>Pdf Code books, Notes and xl sheets supply.</li>

          <li>Certification.</li>
        </ul>
      </div>
    ),
  },
];

export default Tabs;
