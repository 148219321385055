import React from "react";
import "./syllabusPage.css";
import backArrow from "../assets/back-arrow.png";
import { useNavigate } from "react-router-dom";
import SecondFooter from "./innerFooter";

const SyllabusPage = () => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate("/");
    window.scrollTo(2250, 2250);
  };

  return (
    <section className="topic-section-main">
      <button className="back-nav-btn">
        <img
          src={backArrow}
          alt="back-navigate"
          className="back-arrow"
          onClick={handleSubmit}
        />
        <p>Home</p>
      </button>
      <div className="topics">
        <h1 className="h1-1">Topics of STAAD Pro SOFTWARE TRAINING</h1>

        <ul className="basic-topics">
          <li>Overview of Structural Analysis and Design</li>
          <li>Introduction to STAAD Pro. V8i</li>
          <li>Getting Familiar with STAAD Pro. Workspace</li>
          <li>Staad Editor</li>
          <li>GUI</li>
          <li>Structure Wizard</li>
          <li>Creating a New Project and Units</li>
          <li>Global Coordinate System Vs. Local Coordinate</li>
          <li>Beta Angle</li>
          <li>
            Introduction to File, Edit, View, Selection, Geometry, Commands,
            Analyze, M ode
          </li>
          <li>Introduction to Snap Node/Beam</li>
          <li>Insert Node and Add Beam</li>
          <li>Translational Repeat</li>
          <li>Circular Repeat</li>
          <li>Move</li>
          <li>Rotate</li>
          <li>Mirror</li>
          <li>Member Offset</li>
          <li>Connect Beam Along</li>
          <li>
            Intersect, Merge, Break, Splits, Renumber and Stretch selected
            members
          </li>
          <li>
            Introduction to Setup, Geometry, General, Analysis/Print and Design
          </li>
          <li>Assigning Properties</li>
          <li>Assigning Supports</li>
          <li>Group Specification</li>
          <li>Assigning Load</li>
          <li>Structure Analysis</li>
          <li>Material Specification</li>
          <li>Loading</li>
          <li>Self weight</li>
          <li>Nodal Load</li>
          <li>Member Load</li>
          <li>Area Load</li>
          <li>Floor Load</li>
          <li>Wind Load</li>
          <li>Moving Load</li>
          <li>Reference Load</li>
          <li>Plate Load</li>
          <li>Seismic Load</li>
          <li>Automatic Load Combination</li>
          <li>Introduction to Analysis</li>
          <li>Perform Analysis</li>
        </ul>
        <div className="topics-second-div">
          <div className="topics-third-div">
            <h1 className="h1-2">Advanced Topics</h1>
            <ul className="advance-topics">
              <li>Overview of Output Page</li>
              <li>Pre-analysis and Post-analysis Print</li>
              <li>Inactive/Delete Specification</li>
              <li>General Guideline for Design</li>
              <li>Column and Beam Design</li>
            </ul>
          </div>

          <div className="topics-third-div">
            <h1 className="h1-2">LIVE PROJECTS COVERED</h1>

            <ul className="advance-topics">
              <li>Reinforced Concrete Design</li>
              <li>G+9 Building with Response Spectrum Analysis</li>
              <li>Warehouse steel structure design with 5 tons EOT crane</li>
            </ul>
          </div>
        </div>
        <a
          href="https://rzp.io/l/OXaOV8q"
          target="_blank"
          rel="noreferrer"
          className="topic-link-2"
        >
          <button className="topic-button">Buy Now</button>
        </a>
      </div>

      <div className="topics-centre-line"></div>

      <div className="topics">
        <h1 className="h1-1">Topics of Etabs SOFTWARE TRAINING</h1>

        <ul className="basic-topics">
          <li>Introduction</li>
          <li>Structure</li>
          <li>Types of structures</li>
          <li>Basic definitions</li>
          <li>Idealization of structures</li>
          <li>About ETABS</li>
          <li>Features</li>
          <li>Hardware requirements</li>
          <li>ETABS screen organization</li>
          <li>GUI overview</li>
          <li>Unit systems</li>
          <li>Structure geometry</li>
          <li>Coordinate systems (Global and Local)</li>
          <li>The Structural Model</li>
          <li>Units</li>
          <li>Objects and Elements</li>
          <li>Groups</li>
          <li>Coordinate Systems and Grids</li>
          <li>Properties</li>
          <li>Load Cases</li>
          <li>Functions</li>
          <li>Analysis Cases</li>
          <li>Combinations</li>
          <li>Design settings</li>
          <li>Output and Display Definitions</li>
          <li>The Graphical User Interface</li>
          <li>The ETABS Screen</li>
          <li>Main Window</li>
          <li>Menu Bar</li>
          <li>Toolbars</li>
          <li>Display Windows</li>
          <li>Status Line</li>
          <li>Using the Mouse</li>
          <li>Viewing Options</li>
          <li>2-D and 3-D Views</li>
          <li>Perspective</li>
          <li>Pan, Zoom, and 3-D Rotate</li>
          <li>Limits</li>
          <li>Element View Options</li>
          <li>Other Options</li>
          <li>Refreshing the Display Window</li>
          <li>Basic Operations</li>
          <li>File Operations</li>
          <li>Defining Named Entities</li>
          <li>Drawing Objects</li>
          <li>Snap Tools</li>
          <li>Drawing Controls</li>
          <li>Selecting</li>
          <li>Selecting Graphically</li>
          <li>Selecting by Feature</li>
          <li>Editing</li>
          <li>Assigning</li>
          <li>Undo and Redo</li>
          <li>Analyzing</li>
          <li>Displaying</li>
          <li>Graphical Displays</li>
          <li>Model Definition</li>
          <li>Analysis Results</li>
          <li>Function Plots</li>
          <li>Tabular Displays</li>
          <li>Designing</li>
          <li>Locking and Unlocking</li>
          <li>Entering Numerical Data Setting Options</li>
          <li>Define Grid System</li>
          <li>Selecting template</li>
          <li>Entering Grid System Data</li>
          <li>Adding Grid Lines In X, Y and Z Directions</li>
          <li>Define Material Properties</li>
          <li>Specify Design Parameters</li>
          <li>Material Property Data</li>
          <li>Define Section Properties</li>
          <li>Add Frame Section Property</li>
          <li>Specify Frame Section Properties for Beam</li>
          <li>Add New Frame Section Properties for Column</li>
          <li>Specify Area Section Properties for Slab</li>
          <li>Draw</li>
          <li>Drawing beams with different methods</li>
          <li>Drawing Columns with different orientation</li>
          <li>Drawing slabs</li>
          <li>Assigning Properties</li>
          <li>Assigning Properties to Frame Elements</li>
          <li>Assigning Properties to Area Elements</li>
          <li>Assign Restraints</li>
          <li>Assigning Fixed, Pinned, Roller Support at Joints</li>
          <li>Define and Assign Load Cases</li>
          <li>Adding and Assigning Dead Load Case</li>
          <li>Adding and Assigning Live Load Case</li>
          <li>Add rig and Assigning Wind Load Case</li>
          <li>Adding and Assigning Seismic Load Case</li>
          <li>Defining Load Combinations</li>
          <li>
            Adding different Load Combinations for Dead Load, Live Load, Wind
            Load and Seismic Load
          </li>
          <li>View Analysis Results in Tabular Form</li>
          <li>View Analysis Result Diagrams of Frame Elements</li>
          <li>View Analysis Result Contour in Slab Panels</li>
          <li>Concrete Design</li>
          <li>Concrete Frame Design and View Design Results</li>
          <li>Steel Design</li>
          <li>Steel Frame Design and View Design Results</li>
          <li>View Design Parameters</li>
          <li>View Load Combination for Concrete Frame Design</li>
          <li>View Reinforcement for Frame Design</li>
          <li>View Percentage Steel for Frame Design</li>
          <li>Steel Frame Design and View Design Results</li>
          <li>View Load Combination for Steel Frame Design</li>
          <li>Projects</li>
          <li>Project in Concrete Structure</li>
          <li>Project in Steel Structure</li>
        </ul>
        <div className="topics-second-div">
          <div className="topics-third-div">
            <h1 className="h1-2">LIVE PROJECTS COVERED</h1>

            <ul className="advance-topics">
              <li>Reinforced Concrete Design</li>
              <li>G+9 Building with Response Spectrum Analysis</li>
              <li>Warehouse steel structure design with 5 tons EOT crane</li>
            </ul>
          </div>
        </div>
        <a
          href="https://rzp.io/l/OXaOV8q"
          target="_blank"
          rel="noreferrer"
          className="topic-link-2"
        >
          <button className="topic-button">Buy Now</button>
        </a>
      </div>

      <div className="topics-centre-line"></div>

      <div className="topics">
        <h1 className="h1-1">Topics of RCDC SOFTWARE TRAINING</h1>

        <ul className="basic-topics">
          <li>Overview of RCDC software</li>
          <li>Exporting files from Etabs/Staadpro to RCDC</li>
          <li>Advanced concrete Slab Design</li>
          <li>Advanced concrete Beam Design</li>
          <li>Advanced concrete Column Design</li>
          <li>Advanced concrete shear wall, retaining wall Design</li>
          <li>Design corrections and modifications</li>
          <li>Report creation and printing</li>
          <li>Advanced automated detailing drawing creation and saving</li>
          <li>Detailed Design drawing editing to submission</li>
        </ul>
        <a
          href="https://rzp.io/l/OXaOV8q"
          target="_blank"
          rel="noreferrer"
          className="topic-link-2"
        >
          <button className="topic-button">Buy Now</button>
        </a>
      </div>

      <div className="topics-centre-line"></div>

      <div className="topics">
        <h1 className="h1-1">Topics of IDEA Statica SOFTWARE TRAINING</h1>

        <ul className="basic-topics">
          <li>Intro on Steel Connections & various types of connections</li>
          <li>
            How to get beam end forces from analysis file both Staadpro & Etabs
          </li>
          <li>Beam to column flange Shear (pin) connection both bolted</li>
          <li>Beam to column flange shear connection welded</li>
          <li>Beam to column flange moment (fixed) connection bolted</li>
          <li>Beam to column flange moment connection welded</li>
          <li>Same connections in web of columns for items 3, 4, 5, 6</li>
          <li>Truss start type connection</li>
          <li>Brace connection</li>
          <li>Base plate connection both Pin & Fixed</li>
        </ul>
        <a
          href="https://rzp.io/l/OXaOV8q"
          target="_blank"
          rel="noreferrer"
          className="topic-link-2"
        >
          <button className="topic-button">Buy Now</button>
        </a>
      </div>
      <div className="topics-centre-line"></div>

      <div className="topics">
        <h1 className="h1-1">Topics of SAFE SOFTWARE TRAINING</h1>

        <ul className="basic-topics">
          <li>Overview of SAFE software</li>
          <li>Exporting files from Etabs to SAFE</li>
          <li>Verifying design loads and parameters from Etabs</li>
          <li>Advanced concrete Combined Footing Design</li>
          <li>Advanced concrete MAT footing Slab Design</li>
          <li>Report creation and printing</li>
          <li>Advanced automated detailing drawing creation and saving</li>
          <li>Detailed Design drawing editing to submission</li>
        </ul>
        <a
          href="https://rzp.io/l/OXaOV8q"
          target="_blank"
          rel="noreferrer"
          className="topic-link-2"
        >
          <button className="topic-button">Buy Now</button>
        </a>
      </div>
      <SecondFooter />
    </section>
  );
};

export default SyllabusPage;
